
const freeProduct = {
    id: "",
    object: "product",
    active: true,
    attributes: [],
    created: 1234567890, // Replace with actual creation timestamp
    default_price: {
      id: "",
      object: "price",
      active: true,
      billing_scheme: "per_unit",
      created: 1234567891, // Replace with actual creation timestamp
      currency: "usd",
      custom_unit_amount: null,
      livemode: false,
      lookup_key: null,
      metadata: {},
      nickname: null,
      product: "",
      recurring: {
        aggregate_usage: null,
        interval: "", // (e.g., "month", "year")
        interval_count: 1,
        trial_period_days: null,
        usage_type: "", // (e.g., "licensed")
      },
      tax_behavior: "",
      tiers_mode: null,
      transform_quantity: null,
      type: "recurring",
      unit_amount: 0,
      unit_amount_decimal: "",
    },
    description:
      "Explore our Free Subscription offering limited access to features. Enjoy browsing with occasional ads. Upgrade to our premium plans for an ad-free and fully featured experience.",
    features: [
      {
        name: "View Ads for generating reports",
      },
      {
        name: "Limited access to SFST Tests",
      },
      {
        name: "No Billing model",
      },
    ],
    images: [],
    livemode: false,
    metadata: {
      type: "2",
    },
    name: "Free",
    package_dimensions: null,
    shippable: null,
    statement_descriptor: "",
    tax_code: "",
    type: "service",
    unit_label: null,
    updated: 1234567892, // Replace with actual update timestamp
    url: null,
  };

export default freeProduct;
