import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ImageElement from "../componants/ImageElement";
import CustomModal from '../componants/CustomModal';
import "./savedPayment.scss";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { useLocation } from 'react-router-dom';
import apiRequest from '../services/api';
import { useNavigate } from 'react-router-dom';
import Loader from '../componants/Loader';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE);



export default function SavedPayment() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const type = queryParams.get('type');
  const customerId = queryParams.get('customerId');
  const state = JSON.parse(queryParams.get('state')).product;
  const [selectedCard, setSelectedCard] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [loader, setLoader] = useState(true);

  const [DeleteItem, setDeleteItem] = useState({})
  const [modalType, setModalType] = useState(null);


  const cardLogo = useMemo(() => {
    return {
      visa: "cards/visa.svg",
      mastercard: "cards/mastercard.svg",
      diners_club: "cards/Diners-logo.svg",
      amex: "cards/American-card.svg",
      discover: "cards/discover-logo.svg",
      jcb: "cards/jcb-logo.svg",
      unionpay: "cards/unionpay-logo.svg",
      default: "cards/default.svg"
    };
  }, []);

  const [SelectedCardDetails, setSelectedCardDetails] = useState({})

  const handleCardSelection = (itemId, item) => {
    setSelectedCardDetails(item)
    setSelectedCard(itemId);
    console.log('selected ----');
  };
  const callbackUrl = `${process.env.REACT_APP_API_URL}/call_back`; // Replace with your actual callback URL
  // Add the following code to return the desired JSON object
  const checkoutParams = new URLSearchParams({
    type: type,
    token: token,

  });

  const handleCloseModal = () => {
    setShowModal(false);
    // Add the following code to return the desired JSON object
    window.location.assign(callbackUrl);

  };

   const handleCloseModal2 = () => {
    setShowModal(false)
    // Add the following code to return the desired JSON object

  };


  // const appearance = {
  //   theme: 'custom',
  //   variables: {
  //     colorPrimary: '#0570de',
  //     colorBackground: '#ffffff',
  //     colorText: '#303238',
  //     colorDanger: '#df1b41',
  //     fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
  //     spacingUnit: '2px',
  //     borderRadius: '4px',
  //   },
  // };

  // const options = {
  //   style: {
  //     base: {
  //       color: "#00ff00",
  //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //       fontSmoothing: "antialiased",
  //       fontSize: "16px",
  //       backgroundColor: "red",
  //       "::placeholder": {
  //         color: "#aab7c4"
  //       }
  //     },
  //     invalid: {
  //       color: "#fa755a",
  //       iconColor: "#fa755a"
  //     }
  //   }
  // };
  const handleCheckboxChange = (item) => {

    // Call your function to update the default card immediately when checked
    if (!item.isDefault) {
      setLoader(true)
      // Update default card logic goes here
      let params = {
        method: "POST",
        endpoint: "stripe/change_default_payment_method",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        data: {
          customerId,
          newDefaultPaymentMethodId: item?.id
        }
      }
      apiRequest(params).then((response) => {
        if (response?.data?.status) {
          handleSavedCards(1)
          setLoader(false)

        } else {
          setShowModal(true)
          setLoader(false)
        }
      }).catch((error) => {
        setShowModal(true)
        setLoader(false)
      })
    }
  };

  const [SavedCards, setSavedCards] = useState([])

  const handleSavedCards = (key) => {
    setLoader(true)
    let params = {
      method: "POST",
      endpoint: "stripe/get_saved_card",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data: {
        customerId
      }
    }
    apiRequest(params).then((response) => {
      if (response?.data?.status) {
        setSavedCards(response.data.result || [])
        if(key === 1){
          setSelectedCard(1);
        }
        setLoader(false)

      } else {
        setShowModal(true)
        setLoader(false)
      }
    }).catch((error) => {
      setModalType(1);
      setShowModal(true)
      setLoader(false)
    })
  }
  useEffect(() => {
    handleSavedCards(2)
  }, []);

  const handleGoBack = () => {
    window.history.back();
  };

  const handleDeleteCardButton = (item) => {
    setModalType(2);
    setDeleteItem(item)
    setShowModal(true)
  }

  const handleDeleteCard = () => {
    setModalType(2);
    setShowModal(false)
    setLoader(true)
    let params = {
      method: "POST",
      endpoint: "stripe/delete_card",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data: {
        customerId,
        paymentMethodId: DeleteItem.id
      }
    }
    apiRequest(params).then((response) => {
      if (response?.data?.status) {
        handleSavedCards()
        setLoader(false)

      } else {
        setModalType(1);
        setShowModal(true)
        setLoader(false)
      }
    }).catch((error) => {
      setModalType(1);
      setShowModal(true)
      setLoader(false)
    })
  }




  return (
    <>
      {loader ? <Loader /> :

        <Container className="position-relative vh-100">
          <Row>
            <Col sm={12} md={12}>
              <div  className="d-flex flex-row justify-content-center align-items-center py-3 position-relative my-3">
                <div onClick={handleGoBack} className="d-flex ms-0 me-auto back-btn position-absolute start-0"><ImageElement source={'fi_arrow-left.svg'} /></div>
                <div className="page-heading">Payment</div>
              </div>
            </Col>
          </Row>

          <Row>

            <Col>
              <h2 className='section-head'>Saved Cards</h2>
              <div className="d-flex w-100 payment-history-container flex-column">
                {SavedCards.map((item, index) => {
                  return (
                    <div className={`payment-history-list w-100 d-flex flex-wrap flex-row justify-content-start align-items-center px-2 py-4 mb-3 ${selectedCard === index + 1 ? 'selected' : ''}`}>

                      <div className="action-container d-flex flex-row">
                        <input
                          type="radio"
                          name="defaultCard"
                          checked={selectedCard === index + 1}
                          onChange={() => handleCardSelection(index + 1, item)}
                          value={index + 1}
                        />
                      </div>

                      <div className="payment-account-info d-flex flex-row me-2">
                        <div className="d-flex flex-row align-items-center">
                          <div className='me-1'>
                            {/* <ImageElement
                          source="Visa.png"
                          className="tw-max-w-[46px] tw-me-2"
                        /> */}

                            <ImageElement
                              source={
                                cardLogo?.[item?.brand] ||
                                "cards/default.svg"
                              }
                              className={"card_brand_logo"}
                            />
                          </div>
                          <div>
                            <p className="mb-0">XXXX XXXX XXXX {item?.last4}</p>
                          </div>
                        </div>
                      </div>

                      <div className='payment-exp-date me-2'>
                        <p className="mb-0">{item?.exp_month}/{item?.exp_year}</p>
                      </div>



                      {/* {item.isDefault ? <span className='d-tag'>Default</span> : null} */}



                      <div onClick={() => handleDeleteCardButton(item)} className='card-delete me-0 ms-auto'>
                        <ImageElement source={'Delete-icon-card.svg'} />
                      </div>

                      <div className="w-100 mt-3 defaultMark d-flex align-items-center">
                        <input className="form-check-input ms-1 mt-0" type="checkbox" id="flexCheckDefault"
                          checked={item.isDefault}
                          onChange={() => handleCheckboxChange(item)}
                        />
                        <label className="form-check-label" for="flexCheckDefault">
                          Set as default
                        </label>
                      </div>



                    </div>

                  )

                })}
              </div>
            </Col>
          </Row>

          <Row className='mt-3'>

            <Col>

              <div className='addcard_container'>
                <div className='d-flex align-items-center'>
                  <div className="action-container d-flex flex-row">
                    <input
                      type="radio"
                      name="defaultCard"
                      checked={selectedCard === 0}
                      onChange={() => handleCardSelection(0, {})}
                    />
                  </div>
                  <div>
                    <h2 className='section-head mb-0'>Add New Card</h2>
                    {/* <h5 className=' mb-0 '>Add Card</h5> */}
                  </div>
                </div>
                <div className='stripe_add_card_container'>
                  <Elements stripe={stripePromise} options={{
                    style: {
                      base: {
                        color: "#00ff00",
                        fontSize: "16px",
                        backgroundColor: "#0000ff",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "#cdf",
                        // margin:"12px",
                      }
                    }
                  }}>
                    <CheckoutForm data={{ selectedCard, SelectedCardDetails, token, type, customerId, state }} refreshList={handleSavedCards} />
                  </Elements>
                </div>
              </div>
             
            </Col>
          </Row>


          <CustomModal
          className={modalType === 1  ? "my-custom-modal went-wrong" : modalType === 2 ? "my-custom-modal success-modal":"" }
            show={showModal}
            title={
              modalType === 1
                ? "Something went Wrong"
                : modalType === 2
                ? "Delete Card"
                : ""
            }
            modalImage={
              modalType === 1
                ? "wrong.svg"
                : modalType === 2
                ? "success-icon.svg"
                : ""
            }
          >
            
            {modalType === 1 && (
              <p>
                Brace yourself till we get the error fixed. You may also refresh
                the page or try again later.
              </p>
            )}
            {modalType === 2 && (
              <p>Are you sure you want to delete your card? <br />
              Set a new default card to avoid plan cancellation.</p>
            )}
            

            {modalType === 1 ?  
            <button
              onClick={modalType === 1 ? handleCloseModal : null }
              className="soberiety_primaary_btn w-100"
            >
              ok
            </button>
            : modalType === 2 ?  
            <>
            <button onClick={modalType === 2 ? handleDeleteCard : null} 
            className='soberiety_primaary_btn w-100'>Yes</button>
            
            <button onClick={modalType === 2 ? handleCloseModal2 : null } style={{ color: " #84909B" }} className='soberiety_primaary_btn bg-transparent w-100'>No</button>        
            </>
            : ""
            } 
           
          </CustomModal>

        </Container>
      }
    </>
  )
}
