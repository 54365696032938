import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomSelect from "../componants/CustomSelect";
import CustomInput from "../componants/CustomInput";
import CustomTextarea from "../componants/CustomTextarea";
import CustomNumberInput from "../componants/CustomNumberInput";
import "./contactSale.scss";
import ImageElement from "../componants/ImageElement";
import apiRequest from "../services/api";
import { useLocation } from 'react-router-dom';
import CustomModal from "../componants/CustomModal";
import { useNavigate } from 'react-router-dom';
import Loader from "../componants/Loader";

export default function ContactSales() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [loader, setLoader] = useState(false)


  const jsonData = {
    message: 'Data from React JS!',
    details: {
      name: 'Example Name',
      value: 123
    }
  }; 
  const callbackUrl = `${process.env.REACT_APP_API_URL}/backend/call_back`; // Replace with your actual callback URL


  const handleCloseModal = () => {
    setShowModal(false);

    // Perform any necessary logic before the redirect

    // Perform the redirect (302 Found)
    window.location.assign(callbackUrl);
  };

  const handleCloseModal2 = () => {
    // Perform any necessary logic before the redirect

    // Perform the redirect (302 Found)
    window.location.assign(callbackUrl);
    // setShowModal2(false);

  };

  const handleCancel = () => {
    setShowModal(false);// Add the following code to return the desired JSON object
    // Perform any necessary logic before the redirect

    // Perform the redirect (302 Found)
    window.location.assign(callbackUrl);
  };

  
  const departments = [
    { value: null, label: "Select" },
    { value: "301-500", label: "301 to 500 users" },
    { value: "501-750", label: "501 to 750 users" },
    { value: "751-1000", label: "751 to 1000 users" },
    { value: "1001-2000", label: "1001 to 2000 users" },
  ];

  const initialValues = {
    department: "",
    contactNumber: "",
    purpose: "",
    comments: ""

  };

  const validationSchema = Yup.object({
    department: Yup.string().test(
      'is-not-select',
      'Please select a option',
      value => value !== 'Select'
    ).required("Department is required"),
    // department: Yup.string().required("Department is required"),
    contactNumber: Yup.string()
    .matches(/^[0-9+]+$/, 'Contact number must contain only numbers')
    .nullable(true),
    comments: Yup.string(),
    purpose: Yup.string().required("Purpose is required"),
  });
  // console.log("12");
  const handleSubmit = (values, { setSubmitting }) => {
    setLoader(true)
    // Handle form submission
    // console.log(values);
    let params = {
      method: "POST",
      endpoint: "stripe/contact_sales",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${state}`
      },
      data: {
        phone: values.department,
        department_size: values.department,
        comments: values.comments,
        purpose: values.purpose
      }
    }
    apiRequest(params).then((response) => {
      // console.log("🚀 ~ apiRequest ~ response:", response)
      if (response?.data?.status) {
        setLoader(false)

        setShowModal2(true)
      } else {
        // console.log(response?.data?.message)
        setLoader(false)
        setShowModal(true)
      }
    }).catch((error) => {
      setLoader(false)
      setShowModal(true)
      // setShowModal(true)
    })
    // setSubmitting(false);
  };
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <>
    {loader && <Loader />}
    <Container className="position-relative vh-100">
      <Row>
        <Col sm={12} md={12}>
          <div  className="d-flex flex-row justify-content-center align-items-center py-3 position-relative my-3">
            <div  onClick={handleGoBack} className="d-flex ms-0 me-auto back-btn position-absolute start-0"><ImageElement source={'fi_arrow-left.svg'} /></div>
            <div className="page-heading">Contact Sales Form</div>
          </div>
        </Col>
      </Row>
      <Row className="">
        <Col className="contactsale-frm-containe">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="w-100 d-flex flex-column f-frame justify-content-between align-items-stretch contactsale-container" >
                <div className="w-100 max-vh-100 mt-0">
                  <div className="form-row mb-3">

                    <Field
                      label="Department Size:"
                      name="department"
                      as={CustomSelect}
                      options={departments}
                    />

                  </div>
                  <div className="form-row mb-3">

                    <Field
                      label="Contact Number (Optional)"
                      placeholder="Enter contact number here"
                      name="contactNumber"
                      type="tel"
                      as={CustomNumberInput}
                    />

                  </div>
                  <div className="form-row mb-3">

                    <Field
                      label="Purpose:"
                      placeholder="Enter Text here"
                      name="purpose"
                      as={CustomInput} />

                  </div>

                  <div className="form-row mb-3">

                    <Field
                      label="Additional Comments (Optional)"
                      placeholder="Additional Information"
                      name="comments"
                      Rows="4"
                      as={CustomTextarea}
                    />

                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="soberiety_primaary_btn w-100"
                  >
                    Done
                  </button>
                </div>
              </div>


            </Form>
          </Formik>
        </Col>
      </Row>

      <CustomModal
        className="my-custom-modal went-wrong"
        show={showModal}
        onHide={handleCloseModal}
        title="Something went Wrong"
        onCancel={handleCancel}
        modalImage={"wrong.svg"}
        // modalImage={"wrong.png"}
      // className={abcd}
      >
        {/* Add your modal content here */}
        <p>Brace yourself till we get the error fixed. You may also refresh the page or try again later.</p>
        <button onClick={handleCloseModal} className='soberiety_primaary_btn w-100'>ok</button>
      </CustomModal>


      <CustomModal
        className="my-custom-modal success-modal"
        show={showModal2}
        onHide={handleCloseModal2}
        title="Success"
        // onCancel={handleCancel2}
        // onConfirm={handleConfirm}
        modalImage={"success-icon.svg"}
        // modalImage={"success-icon.png"}
      // className={abcd}
      >
        {/* Add your modal content here */}
        <p>Thank you for contacting us. Please check your mail box!</p>
        <button onClick={handleCloseModal2} className='soberiety_primaary_btn w-100 mt-3'>ok</button>
      </CustomModal>
    </Container>
    </>
  );
}
