import React from 'react'
import {  BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from '../pages/Homepages'
import ContactSale from '../pages/ContactSale' // Import the About component
import SavedPayment from '../pages/SavedPayment' 
import PurchasedPlan from '../pages/PurchasedPlan'
import SavedCards from '../pages/SavedCards'

export default function AllRoutes() {
  return (
    <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ContactSale" element={<ContactSale />} />
            <Route path="/checkout" element={<SavedPayment />} />
            <Route path="/purchasedPlan" element={<PurchasedPlan />} />
            <Route path="/cards" element={<SavedCards />} />
        </Routes>
    </Router>
    
  )
}