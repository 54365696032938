import React, { useEffect, useState } from "react";
import "./plans.scss";
import { Col, Container, Row } from "react-bootstrap";
import Stripe from "stripe";
import { useLocation } from "react-router-dom";
import ImageElement from "../componants/ImageElement";
import CustomModal from "../componants/CustomModal";
import apiRequest from "../services/api";
import freeProduct from "../services/data";
import { useNavigate } from "react-router-dom";
import Loader from "../componants/Loader";
const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET);


export default function Home() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get("type");
  const token = queryParams.get("token");
  const onboarding = queryParams.get("onboarding") || "false";
  const [showModal, setShowModal] = useState(false);
  const [CustomerId, setCustomerId] = useState("");
  const [ActivePlan, setActivePlan] = useState({});
  const [loader, setLoader] = useState(true);
  const isEmptyObject = obj => Object.keys(obj).length === 0;


  const [modalType, setModalType] = useState(null);

  const handlePurchasePlan = (product) => {
    const checkoutParams = new URLSearchParams({
      type: type,
      token: token,
      customerId: CustomerId,
      onBoarding: onboarding,
      state: JSON.stringify({ product: product }),
    });
    let paramsObject = {
      method: "POST",
      endpoint: "stripe/get_current_plan",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        type: type == "1" || type == "3" ? 1 : 2,
      },
    };
    apiRequest(paramsObject)
      .then((response) => {
        if (response?.data?.status) {
          if (response?.data?.result?.product_id !== ActivePlan.product_id) {
            setModalType(4)
            setShowModal(true)
          } else {
            if (product.default_price?.unit_amount != 0) {
              // Use the history object to navigate to the checkout page with query parameters
              navigate(`/checkout?${checkoutParams.toString()}`);
            } else {
              setModalType(3)
              setShowModal(true)
            }
          }
        } else {
          setModalType(1);
          setLoader(false);
          setShowModal(true);
        }
      })
      .catch((error) => {
        setModalType(1);
        setLoader(false);
        setShowModal(true);
      });

  };

  const callbackUrl = `${process.env.REACT_APP_API_URL}/call_back`; // Replace with your actual callback URL

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.assign(callbackUrl);
  };

  const handleCloseModal2 = () => {
    window.location.assign(callbackUrl);
  };
  const handleCloseModal4 = () => {
    window.location.reload();
  };

  const handleContactSales = () => {
    navigate("/ContactSale", { state: token });
  };

  const fetchProducts = async () => {
    try {
      // setLoader(true)
      const response = await stripe.products.search({
        query: `active:'true' AND metadata['type']:'${type}'`,
        expand: ["data.default_price"],
      });
      if (type == "2") {
        response.data.push(freeProduct);
      }
      if (type == "3") {
        const response1 = await stripe.products.search({
          query: `active:'true' AND metadata['type']:'1'`,
          expand: ["data.default_price"],
        });
        response.data = [...response.data, ...response1.data];
      }
      // console.log("🚀 ~ fetchProducts ~ response:", response);
      const sortedProducts = response?.data.sort(
        (a, b) => a.default_price.unit_amount - b.default_price.unit_amount
      );
      setLoader(false);
      setProducts(sortedProducts);
    } catch (error) {
      setLoader(false);
      setModalType(1);
      setShowModal(true);
      // console.error(error);
    }
  };
  let handleCurrentPlan = () => {
    let paramsObject = {
      method: "POST",
      endpoint: "stripe/get_current_plan",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        type: type == "1" || type == "3" ? 1 : 2,
      },
    };
    apiRequest(paramsObject)
      .then((response) => {
        if (response?.data?.status) {
          setActivePlan(response?.data?.result);
          fetchProducts();
          // setShowModal(true)
        } else {
          setModalType(1);
          setLoader(false);
          setShowModal(true);
        }
      })
      .catch((error) => {
        setModalType(1);
        setLoader(false);
        setShowModal(true);
      });
  }
  useEffect(() => {
    let params = {
      method: "GET",
      endpoint:
        type == "2"
          ? "user/stripe_id"
          : type == "1" || type == "3"
            ? "department/stripe_id"
            : "",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {},
    };
    apiRequest(params)
      .then((response) => {
        if (
          response?.data?.status &&
          response?.data?.result?.stripe_customer_id
        ) {
          setCustomerId(response?.data?.result?.stripe_customer_id);
          handleCurrentPlan()
        } else {
          setModalType(2);
          setLoader(false);
          setShowModal(true);
        }
      })
      .catch((error) => {
        setModalType(2);
        setLoader(false);
        setShowModal(true);
      });

  }, []);
  const handleGoBack = () => {
    window.location.assign(callbackUrl);
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <div className="d-flex flex-row justify-content-center align-items-center py-3 position-relative my-3">
                {onboarding !== "true" ? <div onClick={handleGoBack} className="d-flex ms-0 me-auto back-btn position-absolute start-0"><ImageElement source={'fi_arrow-left.svg'} /></div>
                  : ""}
                {type == "2" ? (
                  <h2 className="page-heading mb-0 text-start text-md-center">
                    Choose your Plan
                  </h2>
                ) : (
                  <h2 className="page-heading mb-0 text-start text-md-center">
                    Choose your Department Plan
                  </h2>
                )}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col sm={12} md={12}>
              {/* {type == "2" ? (
                <h2 className="page-heading mt-5 text-start text-md-center">
                  Choose your Plan
                </h2>
              ) : (
                <h2 className="page-heading mt-5 text-start text-md-center">
                  Choose your Department Plan
                </h2>
              )} */}
              {type == "2" ? (
                <p className="text-start text-md-center">
                  You can choose to Switch Plans or Cancel the active plan at
                  anytime.
                </p>
              ) : (
                <p className="text-start text-md-center">
                  Subscribe now and switch or cancel your plan anytime!
                </p>
              )}
            </Col>
          </Row>
          <Row className="justify-content-center">
            {products.map((product, index) => (
              <Col key={index} sm={12} md={4} className="plan_conatiner">
                <div className="tier-card px-3 py-3 position-relative mb-3">
                  <div className="tiername-container">
                    <span className="tiername_container">
                      <span className="tiername">{product?.name}</span>
                    </span>
                    {/* </span> */}
                  </div>
                  {product?.default_price?.unit_amount != 0 ? (
                    <p className="mb-1 free-dt">Get 14 days free, then</p>
                  ) : null}
                  <p className="plan-free-info">{product?.planFreeInfo}</p>
                  {product.default_price?.unit_amount != 0 ? (
                    <div className="price-info">
                      <span className="price">
                        $
                        {new Intl.NumberFormat().format(
                          product?.default_price?.unit_amount / 100
                        )}
                        /
                      </span>
                      <span className="duration">
                        {product?.default_price?.recurring.interval}
                      </span>
                    </div>
                  ) : null}
                  <p>{product?.description}</p>
                  {type == "2" ? (
                    <div>
                      <strong className="mb-2 d-inline-block">
                        Features Included:
                      </strong>
                      {product?.features.map((feature, index) => {
                        return (
                          <div
                            key={index}
                            className="feature d-flex align-items-center justify-content-start mb-1"
                          >
                            <span>
                              <ImageElement source={"Icon.svg"} />
                            </span>
                            <span className="ms-2">{feature?.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  {isEmptyObject(ActivePlan) && product?.default_price?.unit_amount === 0 && onboarding === "false" ?
                    <button
                      disabled
                      className="soberiety_white_btn w-100 mt-2"
                      style={{ padding: "16px auto" }}
                    >
                      CURRENT PLAN
                    </button> :
                    product.id == ActivePlan.product_id ||
                      ActivePlan.status == "trialing" ? (
                      <button
                        disabled
                        className="soberiety_white_btn w-100 mt-2"
                        style={{ padding: "16px auto" }}
                      >
                        {product.id == ActivePlan.product_id
                          ? "CURRENT PLAN"
                          : "TRIAL ONGOING"}
                      </button>
                    ) : product?.default_price?.unit_amount <
                      ActivePlan.amount ? (
                      <button
                        disabled
                        className="soberiety_primaary_btn w-100 mt-2"
                        style={{ padding: "16px auto" }}
                      >
                        PURCHASE Plan
                      </button>
                    ) : (
                      <button
                        onClick={() => handlePurchasePlan(product)}
                        className="soberiety_primaary_btn w-100 mt-2"
                        style={{ padding: "16px auto" }}
                      >
                        PURCHASE Plan
                      </button>
                    )}
                  {product?.default_price?.unit_amount != 0 ? (
                    <p className="mt-3 font-11">
                      Free for 14 days, then $
                      {new Intl.NumberFormat().format(
                        product?.default_price?.unit_amount / 100
                      )}{" "}
                      per {product?.default_price.recurring?.interval}. Offer
                      only available if you haven’t tried Monthly or Yearly
                      subscriptions before.
                    </p>
                  ) : null}
                </div>
              </Col>
            ))}
          </Row>

          {type == "1" ? (
            <Row className="justify-content-center align-items-center mb-5">
              <Col sm={12} md={8} className="text-md-center">
                <p className="mt-3 font-11">
                  If your department has over 300 users, please contact sales
                  using the link/button below.
                </p>
                <button
                  onClick={handleContactSales}
                  className="soberiety_primaary_btn w-100"
                  style={{ padding: "16px auto" }}
                >
                  Contact Sales
                </button>
              </Col>
            </Row>
          ) : null}

          <CustomModal
            className={modalType === 1 || modalType === 2 || modalType === 4 ? "my-custom-modal went-wrong" : modalType === 3 ? "my-custom-modal subscription-activate" : ""}
            show={showModal}
            title={
              modalType === 1
                ? "Something went Wrong"
                : modalType === 2
                  ? "Invalid Request"
                  : modalType === 3
                    ? "Subscription Activated" :
                    modalType === 4
                      ? "Session Expired"
                      : ""
            }
            modalImage={
              modalType === 1
                ? "wrong.svg"
                : modalType === 2
                  ? "wrong.svg"
                  : modalType === 3
                    ? "success-icon.svg"
                    : modalType === 4
                      ? "wrong.svg"
                      : ""
            }
          >

            {modalType === 1 && (
              <p>
                Brace yourself till we get the error fixed. You may also refresh
                the page or try again later.
              </p>
            )}
            {modalType === 2 && <p>You don't have access to this page.</p>}
            {modalType === 3 && <p>Your subscription plan to the <strong>Free Subscription</strong> is active!
              Proceed to the dashboard now to explore all features.</p>
            }
            {modalType === 4 && <p>Your session has ended. Please click the button below to update your information.</p>}

            {modalType === 1 || modalType === 3 || modalType === 4 ?
              <button
                onClick={modalType === 1 ? handleCloseModal : modalType === 3 ? handleCloseModal2 : modalType === 4 ? handleCloseModal4 : modalType === 2 ? null : null}
                className="soberiety_primaary_btn w-100"
              >
                ok
              </button>
              : modalType === 2 ? "" : ""
            }

          </CustomModal>

        </Container>
      )}
    </>
  );
}
