import logo from './logo.svg';
import './App.css';
import AllRoutes from "./routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import CustomModal from './componants/CustomModal';

function App() {
  const [isConnected, setIsConnected] = useState(navigator.onLine);
  const [showModal, setShowModal] = useState(false); // Initialize to false

  useEffect(() => {
    const handleConnectionChange = () => setIsConnected(navigator.onLine);
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);

  useEffect(() => {
    if (!isConnected) {
      setShowModal(true); // Open modal if internet is not connected
    }else{
      setShowModal(false); // Open modal if internet is not connected
    }
  }, [isConnected]);

  const callbackUrl = `${process.env.REACT_APP_API_URL}/backend/call_back`; // Replace with your actual callback URL

  const handleCloseModal = () => {
    window.location.assign(callbackUrl);
    setShowModal(false);
  }

  return (
    <>

      <AllRoutes />
      {showModal && ( // Conditionally render the modal
        <CustomModal
          className="my-custom-modal went-wrong1"
          show={showModal}
          title="Network Connection Error"
          modalImage={""}  // Or any other image you want for offline message
        >
          <p>Could not connect to the internet. Please check your internet connection and try again.</p>
          {/* <button onClick={handleCloseModal} className='soberiety_primaary_btn w-100'>ok</button> */}
        </CustomModal>
      )}

    </>

  );
}

export default App;
