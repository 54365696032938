import React, { useEffect } from 'react';
import "./CustomModal.scss"

function CustomModal({ show, onHide, title, children, onCancel, onConfirm, className, modalImage,isAddClass=false, ...props }) {
  
  // console.log("=----", modalImage)
  const modelImage = `/assets/${modalImage}`
  // const modelImage = require(`/assets/${modalImage}`).default;
 
 useEffect(() => {
    if (show) {
      document.body.classList.add('modal-open-noscroll');
    } 
    // console.log("hereasdasd",show)
    return () => {
      // console.log("here")
      document.body.classList.remove('modal-open-noscroll');
    };
  }, [show]);

  const handleModalClick = (e) => {
    e.stopPropagation(); // Prevent closing the modal when clicking inside it
  };

  return (
    <div className={`custom-modal ${show ? 'show' : 'hide'} ${className}`}>
      <div className="custom-modal-content" onClick={handleModalClick}>
        {/* <div className="custom-modal-header">
          <h3>{title}</h3>
          <button onClick={onHide}>&times;</button>
        </div> */}
        <div className="custom-modal-body">
          <div className="modal-body-main">
              <div className='custom-modal-inner'>
                {modalImage && <img src={modelImage} alt="Modal Image" />
                }
                <h3>{title}</h3> 
                {children}  
              </div>
              
          </div>
        </div>
        {/* <div className="custom-modal-footer">
          <button onClick={onCancel}>Cancel</button>
          <button onClick={onConfirm}>Confirm</button>
        </div> */}
      </div>
    </div>
  );
}

export default CustomModal;