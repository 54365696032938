import React, { useState, useEffect } from 'react';

const css = `
.loading {
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid transparent; /* Changed to transparent to make it symmetric */
  border-top-color: #000000; /* color, you can change it */
  border-radius: 50%; /* Changed to 50% to make it circular */
  animation: rotate 1s infinite linear; /* Removed -webkit prefix */
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -15px; /* Half of the padding (15px) to center horizontally */
  margin-top: -15px;
  visibility: hidden; /* Set the initial visibility to hidden */
  transform-origin: center;
}

.loading.visible {
  visibility: visible; /* Show the loader when the 'visible' class is present */
  z-index: 9999;      /* Make sure that our loading animation appears over everything else */
}

@keyframes rotate {
  /* 100% keyframe for clockwise.
     Use 0% instead for anticlockwise */
  100% {
    transform: rotate(360deg);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(97, 96, 96, 0.5); /* semi-transparent black overlay */
  pointer-events: auto; /* Prevent clicks from reaching the underlying elements */
  z-index: 9999; /* Adjust the z-index to make it on top of other elements */
}

.no-scroll {
  overflow: hidden;
}`;

export default function Loader() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Set loading to false after 3 seconds (for testing)

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add('no-scroll'); // Add no-scroll class to body
    } else {
      document.body.classList.remove('no-scroll'); // Remove no-scroll class from body
    }
  }, [isLoading]);

  return (
    <>
      <style>{css}</style>
      {/* <div className='loader-container' style={{ position:'relative', left: '0', top: '0', height: '100%', width: '100%', dispaly: 'inline-block'}}>
        <div className={`loading ${isLoading ? 'visible' : ''}`}></div>
        <div className="overlay"></div>
      </div> */}
      <div className={`loading ${isLoading ? 'visible' : ''}`}></div>
        <div className="overlay"></div>
    </>
  );
}
