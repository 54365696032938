import React from 'react'

let basePath = "/assets/"

function ImageElement({source,previewSource="",...rest}) {
    return ( 
        <>
            <img src={previewSource ? previewSource : `${basePath}${source}`} {...rest} />
        </>
     );
}

export default ImageElement;