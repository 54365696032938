import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import apiRequest from '../services/api';
import CustomModal from '../componants/CustomModal';
import { useNavigate } from 'react-router-dom';
import Loader from '../componants/Loader';


const CheckoutForm = (props) => {
  const { refreshList } = props;
  props = props.data



  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate()
  const [paymentMethod1, setPaymentMethod1] = useState(null);
  const [saveCard, setSaveCard] = useState(false); // Added checkbox state
  const [ActivePlan, setActivePlan] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [CardError, setCardError] = useState({})
  const [loader, setLoader] = useState(false);
  const [IsEmpty, setIsEmpty] = useState({})
  const [Declined, setDeclined] = useState("")
  const [modalType, setModalType] = useState(null);


  const callbackUrl = `${process.env.REACT_APP_API_URL}/call_back`; // Replace with your actual callback URL

  const handleCloseModal = () => {
    setShowModal(false);
    // Add the following code to return the desired JSON object
    // navigate(`/?type=${props?.type}&token=${props?.token}`);

  };

  const handleCloseModal2 = () => {
    // setShowModal2(false)
    // Add the following code to return the desired JSON object
    window.location.assign(callbackUrl);

  };
  const handleCloseModal3 = () => {
    setShowModal(false)

  };

  useEffect(() => {
    const fetchActivePlan = async () => {
      try {
        const response = await apiRequest({
          method: "POST",
          endpoint: "stripe/get_current_plan",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${props?.token}`
          },
          data: {
            type: props?.type == "1" || props?.type == "3" ? 1 : 2
          }
        });

        if (response?.data?.status) {
          setActivePlan(response?.data?.result);
          // setShowModal2(true)
        } else {
          setModalType(1)
          setShowModal(true)
        }
      } catch (error) {
        setModalType(1)
        setShowModal(true)
      }
    };

    fetchActivePlan();
  }, []);

  const handleSaveCard = async () => {
    setLoader(true)
    // **Corrected access to card element:**
    const cardElement = elements.getElement(CardElement);
    let paymentMethod2 = "";


    if (!stripe || !cardElement) {
      // Handle missing Stripe or cardElement gracefully
      console.error('Missing Stripe or card element');
      return;
    }
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        // **Pass the correct cardElement:**
        card: cardElement,
      });

      if (error) {
        console.error(error);
        setLoader(false)
        setModalType(4)

        setShowModal(true)
      } else {

        // setPaymentMethod1(paymentMethod);
        paymentMethod2 = paymentMethod.id
        // Handle paymentMethod submission to your backend here
      }
    } catch (error) {
      setLoader(false)
      setModalType(4)

      setShowModal(true)
      console.error('Error creating payment method:', error);
    }
    let params = {
      method: "POST",
      endpoint: "stripe/add_card",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${props.token}`
      },
      data: {
        customerId: props.customerId,
        paymentMethodId: paymentMethod2,
        saveCard: saveCard
      }
    }
    apiRequest(params).then((response) => {
      if (response?.data?.status) {
        setLoader(false);
        cardElement.clear();
        setCardError(response?.data?.result)
        if (!response?.data?.result?.exist) {
          refreshList()
        }
      } else {
        setLoader(false)
        setModalType(4)
        setShowModal(true)
      }
    }).catch((error) => {
      setLoader(false)
      console.log(error)
      setModalType(4)
      setShowModal(true)
    })
  };
  const isEmptyObject = obj => Object.keys(obj).length === 0;

  const handlePaymentSubmit = async (event) => {
    setShowModal(false)
    setLoader(true)
    event.preventDefault();
    let paymentMethod2 = "";
    if (props?.selectedCard !== 0) {
      let params = {
        method: "POST",
        endpoint: isEmptyObject(ActivePlan) ? "stripe/create_subscription" : "stripe/upgrade_subscription",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${props?.token}`
        },
        data: isEmptyObject(ActivePlan) ? {
          customerId: props?.customerId,
          paymentMethodId: props?.SelectedCardDetails?.id,
          priceId: props?.state?.default_price?.id,
          userType: props?.type

        } : {

          customerId: props?.customerId,
          subscriptionId: ActivePlan.subscription_id,
          newPriceId: props?.state?.default_price?.id,
          paymentMethodId: props?.SelectedCardDetails?.id,
        }
      }
      apiRequest(params).then((response) => {
        if (response?.data?.status) {
          setModalType(2)
          setLoader(false)
          setShowModal(true)
        } else {
          if (response?.data?.result?.isDeclined) {
            setDeclined(response.data.message)
          }
          setModalType(1)
          setLoader(false)
          setShowModal(true)
        }
      }).catch((error) => {
        if (error?.response?.data?.result?.isDeclined) {
          setDeclined(error?.response?.data?.message)
        }
        setModalType(1)
        setLoader(false)
        setShowModal(true)
      })
    } else {
      // **Corrected access to card element:**
      const cardElement = elements.getElement(CardElement);

      if (!stripe || !cardElement) {
        // Handle missing Stripe or cardElement gracefully
        console.error('Missing Stripe or card element');
        return;
      }
      try {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          // **Pass the correct cardElement:**
          card: cardElement,
        });

        if (error) {
          console.error(error);
          setModalType(1)
          setLoader(false)
          setShowModal(true)
        } else {

          // setPaymentMethod1(paymentMethod);
          paymentMethod2 = paymentMethod.id
          // Handle paymentMethod submission to your backend here
        }
      } catch (error) {
        setModalType(1)
        setLoader(false)
        setShowModal(true)
        console.error('Error creating payment method:', error);
      }
      let params = {
        method: "POST",
        endpoint: !isEmptyObject(ActivePlan) ? "stripe/upgrade_subscription" : "stripe/create_subscription",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${props.token}`
        },

        data: isEmptyObject(ActivePlan) ? {
          customerId: props?.customerId,
          paymentMethodId: paymentMethod2,
          priceId: props?.state?.default_price?.id,
          userType: props?.type

        } : {

          customerId: props?.customerId,
          subscriptionId: ActivePlan.subscription_id,
          newPriceId: props?.state?.default_price?.id,
          paymentMethodId: paymentMethod2,
        }
      }
      apiRequest(params).then((response) => {
        if (response?.data?.status) {
          setLoader(false)
          cardElement.clear()
          setModalType(2)
          setShowModal(true)

        } else {
          if (response?.data?.result?.isDeclined) {
            setDeclined(response.data.message)
          }
          setLoader(false)
          setModalType(1)
          setShowModal(true)
          // setShowModal(true)
        }
      }).catch((error) => {
        if (error?.response?.data?.result?.isDeclined) {
          setDeclined(error?.response?.data?.message)
        }
        setLoader(false)
        setModalType(1)
        console.log(error)
        setShowModal(true)
        // setShowModal(true)
      })
    }


  };
  const handleSubmit = (e) => {
    e.preventDefault()
    setModalType(3)
    setShowModal(true)
  }
  useEffect(() => {
    let timeoutId;
    if (CardError) {
      timeoutId = setTimeout(() => {
        setCardError({ exist: false });
      }, 5000); // Remove error after 3 seconds
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [CardError]);
  return (
    <>
      {loader && <Loader />}
      <form onSubmit={handleSubmit} className='position-relative'>
        <div style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: '#cdf', padding: '15px', borderRadius: '8px', marginTop: '10px' }}>
          <CardElement
            onChange={(e) => setIsEmpty(e)}
            options={{
              style: {
                base: {
                  color: "#003263",
                  fontSize: "16px",

                }
              }
            }}
          />
        </div>
        {CardError?.exist ?
          <span className='custom-error'>Card already exist</span> :
          null}
        <div className='d-flex align-items-center mt-3'>
          <div>
            <button disabled={isEmptyObject(IsEmpty) ? true : (IsEmpty?.empty || (IsEmpty?.brand === "unknown") || (IsEmpty.error !== undefined)) ? true : false} type="button" class=" soberiety_primaary_btn my-2 py-2" onClick={handleSaveCard}>Add Card</button> </div>
        </div>
        <div className='w-100 my-3 d-flex align-items-center justify-content-start justify-content-md-center'>
          <button disabled={props?.selectedCard === 0 && ((isEmptyObject(IsEmpty)) || IsEmpty?.empty || IsEmpty?.brand === "unknown" || IsEmpty?.error !== undefined) ? true : false} type="submit" className='soberiety_primaary_btn w-100 my-3'>Purchase</button>
        </div>
      </form>
      <CustomModal
        className={
          modalType === 1
            ? "my-custom-modal went-wrong"
            : modalType === 2
              ? "my-custom-modal subscription-activate"
              : modalType === 3
                ? "my-custom-modal success-modal"
                : modalType === 4
                  ? "my-custom-modal went-wrong"
                  : ""
        }
        show={showModal}
        title={
          modalType === 1
            ? Declined !== ""
              ? "Payment failed"
              : "Something went Wrong"
            : modalType === 2
              ? "Subscription Activated!"
              : modalType === 3
                ? "Activate Subscription"
                : modalType === 4
                  ? "Failed to Add card!"
                  : ""
        }
        modalImage={
          modalType === 1
            ? "wrong.svg"
            : modalType === 2
              ? "success-icon.svg"
              : modalType === 3
                ? "Group.svg"
                : modalType === 4
                  ? "wrong.svg"
                  : ""
        }
      >
        {modalType === 1 ? (
          Declined !== "" ? (
            <>
              <p className='mb-0'>{Declined}</p>
              <p>Please check your card details and try again later!</p>
              <p></p>
            </>
          ) : (
            <p>
              "Brace yourself till we get the error fixed. You may also refresh
              the page or try again later."
            </p>
          )
        ) : (
          ""
        )}
        {modalType === 2 && (
          <p>
            Your subscription plan to the <strong>{props?.state?.name}</strong>{" "}
            is active! Proceed to the dashboard now to explore all features.
          </p>
        )}
        {modalType === 3 && (
          <p>Are you sure you want to proceed with the selected plan?</p>
        )}
        {modalType === 4 && (
          <p>Please check the card details and try adding your card again!</p>
        )}

        {modalType === 1 ? (
          <button
            onClick={handleCloseModal}
            className="soberiety_primaary_btn w-100"
          >
            ok
          </button>
        ) : modalType === 2 ? (
          <button
            onClick={handleCloseModal2}
            className="soberiety_primaary_btn w-100"
          >
            ok
          </button>
        ) : modalType === 3 ? (
          <>
            <button
              onClick={handlePaymentSubmit}
              className="soberiety_primaary_btn w-100"
            >
              Yes
            </button>
            <button
              onClick={handleCloseModal3}
              style={{ color: " #84909B" }}
              className="soberiety_primaary_btn bg-transparent w-100"
            >
              No
            </button>
          </>
        ) : modalType === 4 ? (
          <button
            onClick={handleCloseModal}
            className="soberiety_primaary_btn w-100"
          >
            ok
          </button>
        ) : (
          ""
        )}
      </CustomModal>
    </>



  );
};

export default CheckoutForm;
