import React from 'react';
import { useField } from 'formik';

const CustomTextarea = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea
        {...field}
        {...props}
        className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
      />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CustomTextarea;
