import React from 'react';
import { useField } from 'formik';
import { object, string } from 'yup';

const CustomSelect = ({ label, options, ...props }) => {
  const [field, meta] = useField(props);

  // Define a Yup schema for validation
  const schema = object({
    // Assuming the field name is the same as the props.name value
    [props.name]: string().required('This field is required'),
  });

  // Run the validation schema on the current field value
  schema.validateAt(props.name, props.values)
    .then(() => console.log('Valid'))
    .catch(err => console.log(err.message));

  return (
    <div>
      <label htmlFor={props.id || props.name}>{label}</label>
      <select
        {...field}
        {...props}
        options={options}
        className={`form-select ${meta.touched && meta.error ? 'is-invalid' : ''}`}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </div>
  );
};

export default CustomSelect;
