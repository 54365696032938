import axios from "axios";

const apiRequest = async ({ method, endpoint, headers, data }) => {
    return axios({
        method,
        url: `${process.env.REACT_APP_API_URL}/${endpoint}`,
        headers,
        data,
    });
};

export default apiRequest;
