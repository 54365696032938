import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import ImageElement from '../componants/ImageElement'
import apiRequest from '../services/api';
import Stripe from 'stripe';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../componants/CustomModal';
import moment from 'moment';
import Loader from '../componants/Loader';
import freeProduct from '../services/data';
const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET);


export default function PurchasedPlan() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');
  const token = queryParams.get('token');
  const [ActivePlan, setActivePlan] = useState({})
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [loader, setLoader] = useState(true)

  const [modalType, setModalType] = useState(null);


  const callbackUrl = `${process.env.REACT_APP_API_URL}/backend/call_back`; // Replace with your actual callback URL

  const handleCloseModal = () => {
    // setShowModal2(false)
    setShowModal(false);
    // setShowModal3(false)
    // Add the following code to return the desired JSON object
    // window.location.assign(callbackUrl);

  };
  const handleCloseModal1 = () => {
    // setShowModal2(false)
    // setShowModal(false);
    // setShowModal3(false)
    // Add the following code to return the desired JSON object
    window.location.assign(callbackUrl);

  };

  const isEmptyObject = obj => Object.keys(obj).length === 0;

  const fetchProducts = async (product_id) => {

    try {
      if (type === "2" && product_id === "") {
        setProducts(freeProduct);
        setLoader(false)
      } else {
        console.log("===")

        const response = await stripe.products.retrieve(product_id, {
          expand: ['default_price'],
        });
        setProducts(response);
        setLoader(false)
      }

    } catch (error) {
      setLoader(false)
      setModalType(3);
      setShowModal(true);
    }
  };
  const handleCancelButton = () => {
    setModalType(4)
    setShowModal(true)

  }
  const handleCancel = async () => {
    setModalType(3)
    setShowModal(false)
    setLoader(true)

    let paramsObject = {
      method: "PUT",
      endpoint: "stripe/cancel_subscription",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data: {
        subscriptionId: ActivePlan?.subscription_id
      }
    }
    // console.log("🚀 ~ handleCancel ~ paramsObject:", paramsObject)
    apiRequest(paramsObject).then((response) => {
      if (response?.data?.status) {
        setLoader(false)
        setModalType(3)
        setShowModal(true)
      } else {
        setLoader(false)
        setModalType(1)
        setShowModal(true)
      }
    }).catch((error) => {
      setLoader(false)
      setModalType(1)
      setShowModal(true)
    })
  };

  useEffect(() => {

    setLoader(true)
    let paramsObject = {
      method: "POST",
      endpoint: "stripe/get_current_plan",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data: {
        type: type == "1" || type == "3" ? 1 : 2
      }
    }
    apiRequest(paramsObject).then((response) => {
      if (response?.data?.status) {
        if (!isEmptyObject(response?.data?.result)) {
          setActivePlan(response?.data?.result)
          fetchProducts(response?.data?.result?.product_id);
        } else {
          if (type === "2") {
            fetchProducts("");
          } else {
            setModalType(2)
            setLoader(false)
            setShowModal(true)
          }

        }

      } else {
        setModalType(1)
        setLoader(false)
        setShowModal(true)
      }
    }).catch((error) => {
      setModalType(1)
      setLoader(false)
      setShowModal(true)
    })

  }, []);
  const handleUpgrade = async (product_id) => {
    const checkoutParams = new URLSearchParams({
      type: type,
      token: token,

    });
    navigate(`/?${checkoutParams.toString()}`);

  };


  const handleGoBack = () => {
    window.location.assign(`${process.env.REACT_APP_API_URL}/backend/call_back`);
  };


  return (
    <>
      {loader ? <Loader /> :
        <Container className="position-relative vh-100 purchased_plan_main">
          <Row>
            <Col sm={12} md={12}>
              <div onClick={handleGoBack} className="d-flex flex-row justify-content-center align-items-center py-3 position-relative my-3">
                <div className="d-flex ms-0 me-auto back-btn position-absolute start-0"><ImageElement source={'fi_arrow-left.svg'} /></div>
                <div className="page-heading">Subscription Plan</div>
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col sm={12} md={12} className="">
              <div className='d-flex w-100 justify-content-start align-items-start current-plan-info'>
                <div>
                  <h4 className='currentPlanHead'> Current Subscription Plan </h4>
                  {isEmptyObject(ActivePlan) && type === "2" ?
                    <p className='small renew_date mb-0'> Free plan with ads</p>
                    : <p className='small renew_date mb-0'> {ActivePlan?.cancel_at_period_end || ActivePlan?.isFinalized ? "Expires on" : "Renews on"}  {moment(ActivePlan?.current_period_end).utc().format("DD/MM/YY")}</p>
                  }
                </div>
                <div className='me-0 ms-auto'>
                  <button onClick={handleUpgrade} disabled={ActivePlan.status === "trialing"} className='upgrade_card btn btn-link p-0 m-0'>Upgrade</button>
                </div>
              </div>
            </Col>
            <Col className='mt-3'>
              <div className='plan_conatiner col-md-4 col-sm-12'>
                <div className='tier-card px-3 py-3 position-relative mb-3'>
                  <div className='tiername-container'>
                    <span className='tiername_container'>
                      <span className='tiername'>
                        {products.name}
                      </span>

                    </span>
                    {/* </span> */}
                  </div>
                  {products?.default_price?.unit_amount != 0 ? (
                    <p className="mb-1 free-dt">Get 14 days free, then</p>
                  ) : null}
                  <p className='plan-free-info'>
                    {/* Get 10 days free, then */}
                    {products?.planFreeInfo}
                  </p>
                  <div className='price-info'>
                    <span className='price'>
                      ${new Intl.NumberFormat().format(products?.default_price?.unit_amount / 100)}/

                    </span>
                    <span className='duration'>
                      month
                      {/* {product?.default_price?.recurring.interval} */}
                    </span>
                  </div>

                  <p className='plan-desc1'>
                    {/* Upgrade to our Monthly Subscription plan for an ad-free experience. Pay a low monthly fee to access all features and tools without interruptions. */}
                    {products?.description}
                  </p>
                  {type == "2" ? (
                    <div>
                      <strong className="mb-2 d-inline-block">
                        Features Included:
                      </strong>
                      {products?.features.map((feature, index) => {
                        return (
                          <div
                            key={index}
                            className="feature d-flex align-items-center justify-content-start mb-1"
                          >
                            <span>
                              <ImageElement source={"Icon.svg"} />
                            </span>
                            <span className="ms-2">{feature?.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}



                  <div className='d-flex w-100 justify-content-center mt-3'>
                    {ActivePlan?.cancel_at_period_end || ActivePlan?.isFinalized || (isEmptyObject(ActivePlan) && type === "2")
                      ? <button disabled className='btn btn-link custom-btn-link mx-auto'>
                        Cancel Subscription
                      </button>
                      : <button onClick={handleCancelButton} className='btn btn-link custom-btn-link mx-auto'>
                        Cancel Subscription
                      </button>}
                  </div>
                  {isEmptyObject(ActivePlan) && type === "2" ?
                    <div className='mt-3'> <span style={{ width: '24px', dispaly: 'inline-block' }}><ImageElement source={'warning.svg'} style={{ width: '24px', dispaly: 'inline-block', margin: '0 5px 0 0' }} /></span>You cannot cancel this subscription. This is a free subscription.</div>
                    :
                    ActivePlan?.cancel_at_period_end ?
                      <div className='mt-3'> <span style={{ width: '24px', dispaly: 'inline-block' }}><ImageElement source={'warning.svg'} style={{ width: '24px', dispaly: 'inline-block', margin: '0 5px 0 0' }} /></span>You have already cancelled your subscription. This subscription will expire on {moment(ActivePlan?.current_period_end).utc().format("D MMMM YYYY")}.</div>
                      : ActivePlan?.status === "trialing" ?
                        <div className='mt-3'> <span style={{ width: '24px', dispaly: 'inline-block' }}><ImageElement source={'warning.svg'} style={{ width: '24px', dispaly: 'inline-block', margin: '0 5px 0 0' }} /></span>You are currently on a Free Trial! In Order to upgrade to a subscription plan, you need to either cancel the ongoing Free Trial or wait for it to expire.</div>
                        : ActivePlan?.isFinalized ?
                          <div className='mt-3'> <span style={{ width: '24px', dispaly: 'inline-block' }}><ImageElement source={'warning.svg'} style={{ width: '24px', dispaly: 'inline-block', margin: '0 5px 0 0' }} /></span>Recurring payment for this subscription has been initiated. Please try again after 6-8 hours.</div>
                          : null}
                </div>
              </div>

            </Col>
          </Row>

          <CustomModal
            className={modalType === 1 ? "my-custom-modal went-wrong" : modalType === 2 ? "my-custom-modal went-wrong" : modalType === 3 ? "my-custom-modal success-modal" : modalType === 4 ? "my-custom-modal success-modal" : ""}
            show={showModal}
            title={
              modalType === 1
                ? "Something went Wrong"
                : modalType === 2
                  ? "Oops!"
                  : modalType === 3
                    ? "Cancel Subscription"
                    : modalType === 4
                      ? "Cancel Subscription"
                      : ""
            }
            modalImage={
              modalType === 1
                ? "wrong.svg"
                : modalType === 2
                  ? "wrong.svg"
                  : modalType === 3
                    ? "Cancel-subscription.svg"
                    : modalType === 4
                      ? "Cancel-subscription.svg"
                      : ""
            }
          >

            {modalType === 1 &&
              <p>Brace yourself till we get the error fixed. You may also refresh the page or try again later.</p>
            }
            {modalType === 2 &&
              <p>You don't have any active subscription plan! Please proceed to buy one.</p>
            }
            {modalType === 3 &&
              (
                <p>Your subscription will be cancelled at the end of your billing period.<br /> <strong>{moment(ActivePlan?.current_period_end).utc().format("D MMMM YYYY")}</strong></p>
              )
            }
            {modalType === 4 &&
              <p>Are you sure you want to cancel your current subscription?</p>
            }

            {modalType === 1 ?
              <button onClick={handleCloseModal} className='soberiety_primaary_btn w-100'>ok</button>
              : modalType === 2 ?
                <button onClick={handleCloseModal1} className='soberiety_primaary_btn w-100'>ok</button>
                : modalType === 3 ?
                  <button onClick={handleCloseModal1} className='soberiety_primaary_btn w-100'>ok</button>
                  : modalType === 4 ?
                    <>
                      <button onClick={handleCancel} className='soberiety_primaary_btn w-100'>Yes</button>
                      <button onClick={handleCloseModal} style={{ color: " #84909B" }} className='soberiety_primaary_btn bg-transparent w-100'>No</button></>
                    : ""
            }

          </CustomModal>

        </Container >
      }
    </>
  )
}
