import React from 'react';
import { useField } from 'formik';

const CustomNumberInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  const handleChange = (e) => {
    const { value } = e.target;
    const newValue = value.replace(/[^0-9+]/g, '');  // Filter out non-numeric characters
    const fakeEvent = {
      target: {
        name: field.name,
        value: newValue,
      },
    };
    field.onChange(fakeEvent);
  };

  return (
    <div>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input
        {...field}
        {...props}
        onChange={handleChange} // Use the handleChange function
        className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
      />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CustomNumberInput;